import "./lib/bootstrap/affix.js"
import "./lib/bootstrap/alert.js"
import "./lib/bootstrap/button.js"
import "./lib/bootstrap/carousel.js"
import "./lib/bootstrap/collapse.js"
import "./lib/bootstrap/dropdown.js"
import "./lib/bootstrap/modal.js"
import "./lib/bootstrap/popover.js"
import "./lib/bootstrap/scrollspy.js"
import "./lib/bootstrap/tab.js"
import "./lib/bootstrap/tooltip.js"
import "./lib/bootstrap/transition.js"

import "./lib/jquery.ba-outside-events.js"
import "./lib/jquery.fancybox.pack.js"
import "./lib/jquery.hoverIntent.js"
import "./lib/jquery.magnific-popup.min.js"
import "./lib/jquery.menu-aim.js"

import "./lib/pannellum.js"
import "./lib/picturefill.min.js"
import "./lib/slick.min.js"

import "./../scss/main.scss"

window.jQuery = $;
window.$ = $;

$(document).ready(function() {

    //Menü-Icon animation auslösen
    $('.menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.nav0-1').slideToggle('fast');
    });

    // Prüfen ob Untermenüpunkte existieren
    var submenuitem = $('nav ul li');
    submenuitem.find('div').before('<span class="dropdown-icon visible-xs"><img alt="Dropdown-Icon" src="img/angle-down.svg"></span>');

    // Submenüebenen (de)aktivieren
    $('.dropdown-icon').click(function () {
        var sub = $(this).next('div');
        if (sub) {
            $(sub).toggleClass('active');
        }
        $(this).toggleClass("rotate");
    });

    //$(document).on('click', 'a[href^="#"]', function (event) {
    //    event.preventDefault();
    //
    //    $('html, body').animate({
    //        scrollTop: $($.attr(this, 'href')).offset().top
    //    }, 500);
    //});

    $('.zoom-view-big').click(function(){
        var baseFontSize = parseInt($('html').css('font-size'));
        if (baseFontSize < 18) {
            $('html').css('font-size', "+=2");
        }
    });

    $('.zoom-view-small').click(function(){
        var baseFontSize = parseInt($('html').css('font-size'));
        console.log(baseFontSize);

        if (baseFontSize > 14) {
            $('html').css('font-size', "-=2");
        }
    });


    $('.headerimg').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.titlepic').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    // Zum Seitenanfang scrollen
    $('.scroll2top').click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    function activateSubmenu(row) {
        var $row = $(row),
            submenuId = $row.data("submenuId"),
            $submenu = $("#" + submenuId);
        //Submenü anzeigen
        $submenu.css("display", "block");
    }

    function deactivateSubmenu(row) {
        var $row = $(row),
            submenuId = $row.data("submenuId"),
            $submenu = $("#" + submenuId);
        // Submenü ausblenden
        $submenu.css("display", "none");
    }

    function enterSubmenu(row) {
        var $row = $(row),
            submenuId = $row.data("submenuId"),
            $submenu = $("#" + submenuId);
        //Submenü anzeigen
        $submenu.css("display", "block");
    }


    //$('.nav0-1__listitem').click(function(){
    //    $(this).toggleClass('open').children('.meganav').toggle();
    //    $(this).siblings().removeClass('open').children('.meganav').hide();
    //});

    //$(".nav0-1").bind( "clickoutside", function(event){
    //    $('.meganav').hide();
    //});


    /*
    $(".dropdown-submenu .nav0-2__listitem-link").on("click", function(e) {
        $(this).parent('.nav0-2__listitem').siblings('.nav0-2__listitem').find('.nav0-3').hide();
        $('.nav0-2__listitem').removeClass('active');
        $(this)
            .next(".nav0-3")
            .toggle();
        $(this).parent('.nav0-2__listitem').toggleClass('active');
        e.stopPropagation();
        e.preventDefault();
    });

    $(".nav0-1__listitem").click(function () {
        if ( $(window).width() > 767 ) {
            var firstNav02 = $(this).find(".nav0-2__listitem")[0];
            if ($(firstNav02).find('.nav0-3').length) {
                $(firstNav02).addClass('active').children('.nav0-3').show();
            }
        }
    });
*/
    $('h1[align=center]').addClass('center');

    $('.infobar__listitem-anchor').click(function(){
        var target = "#" + $(this).data("target");
        setTimeout(function() {
            $(target).next('div.collapse').collapse('show');
        },600);

        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
    });

    !function($,window,document,undefined){$.fn.doubleTapToGo=function(action){return"ontouchstart"in window||navigator.msMaxTouchPoints||navigator.userAgent.toLowerCase().match(/windows phone os 7/i)?(this.each("unbind"===action?function(){$(this).off(),$(document).off("click touchstart MSPointerDown",handleTouch)}:function(){function handleTouch(e){for(var resetItem=!0,parents=$(e.target).parents(),i=0;i<parents.length;i++)parents[i]==curItem[0]&&(resetItem=!1);resetItem&&(curItem=!1)}var curItem=!1;$(this).on("click",function(e){var item=$(this);item[0]!=curItem[0]&&(e.preventDefault(),curItem=item)}),$(document).on("click touchstart MSPointerDown",handleTouch)}),this):!1}}(jQuery,window,document);
    $('.touch .nav0-1__listitem:has(.nav0-2)').doubleTapToGo();


    if ($(window).width() > 767) {

        //Aktivierung jquery Menu Aim Plugin
        $(".menuaim").menuAim({
            activate: activateSubmenu,
            deactivate: deactivateSubmenu,
            //enter: enterSubmenu,
            //exit: exitSubmenu,
            submenuDirection: "right"
        });

    }

    $(window).on('resize', function() {

        if ($(window).width() > 767) {
            //Aktivierung jquery Menu Aim Plugin
            $(".menuaim").menuAim({
                activate: activateSubmenu,
                deactivate: deactivateSubmenu,
                //enter: enterSubmenu,
                //exit: exitSubmenu,
                submenuDirection: "right"
            });
        }

        if ($(window).width() < 768) {
            $(".menuaim").menuAim("destroy");
        }

    });

    /*
    $('.nav0-1__listitem').find('.nav0-1__listitem-link').on('click', function(event) {

        event.preventDefault();

        if($(this).parent().hasClass('open')) {
            location.assign($(this).attr('href'));
        } else {
            $('.nav0-1__listitem').find('.nav0-1__listitem-link').removeClass('open');
            $(this).parent().addClass('open');
        }

    });
*/


    //$( '.nav0-2:has(ul)' ).doubleTapToGo();

    $('.nav0-3__listitem.active').parents('.nav0-2__listitem').addClass('active');

});
